import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'; // Import der Umgebungsvariablen
import { Observable } from 'rxjs';

interface VideoMeta {
	_id: string;
	fileName: string;
	description: string;
	thumbnail: string;
	filePath: string;
	name: string;
	status: string;
	location: any;
	metadata: any;
	category: {
		_id: string;
		name: string;
	};
	uploadedDate: string;
	uploadedFrom: {
		forename: string;
		surname: string;
		username: string;
	};
	size?: number;  // Größe ist optional
}

@Injectable({
	providedIn: 'root'
})
export class VideoMetaService {

	private baseUrl = environment.apiUrl; // Verwendung der API-URL aus den Umgebungsvariablen
	
	constructor(
		private http: HttpClient
	) {
	}

	getAllVideoMetas(): Observable<VideoMeta[]> {
		return this.http.get<VideoMeta[]>(`${this.baseUrl}/videoMeta/getAll`);
	}	

	updateVideoMeta(video: any): Observable<any> {
		return this.http.put<any[]>(`${this.baseUrl}/videoMeta/update`, video);
	}

	changeStateOfVideoMeta(videoId: string, newStatus: string): Observable<any> {
		return this.http.put<any>(`${this.baseUrl}/videoMeta/changeState`, { id: videoId, status: newStatus });
	}

	deleteVideoMeta(videoId: any): Observable<any> {
		return this.http.delete<any[]>(`${this.baseUrl}/videoMeta/delete/` + videoId);
	}

    enableVideoMetas(videoIds: Array<any>): Observable<any> {
		return this.http.put<any>(`${this.baseUrl}/videoMeta/enable`, { videoIds: videoIds });
	}

    disableVideoMetas(videoIds: Array<any>): Observable<any> {
		return this.http.put<any>(`${this.baseUrl}/videoMeta/disable`, { videoIds: videoIds });
	}

    getPendingVideos(): Observable<any> {
        return this.http.get(`${this.baseUrl}/videoMeta/pending`);
    }	
}
