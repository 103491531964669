import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private pendingVideosSubject = new BehaviorSubject<number>(0); // Startwert 0

  pendingVideos$ = this.pendingVideosSubject.asObservable(); // Observable für die Komponenten, die den Wert abonnieren wollen

  setPendingVideos(count: number) {
    this.pendingVideosSubject.next(count); // Setzt die Anzahl der pending Videos
  }
}
